import { MLEnum, Unit } from '../TypeScript/enums';
import Viewer from 'viewerjs';

export const stepSizeOptions = {
  [Unit.Meter]: [
    {
      value: 0.05,
      display: '0.05 meters',
    },
    {
      value: 0.2,
      display: '0.2 meters',
    },
    {
      value: 1.0,
      display: '1.0 meter',
    },
  ],
  [Unit.Foot]: [
    {
      value: 0.03,
      display: '0.03 feet',
    },
    {
      value: 0.2,
      display: '0.2 feet',
    },
    {
      value: 1.0,
      display: '1.0 foot',
    },
  ],
};

export const fallbackStepSizeOptions = [0.05, 0.2, 1.0].map((value) => ({
  display: value,
  value,
}));

export const roqenetColors = {
  [`${MLEnum.roqenet}-Rubble Zone A`]: '#FFC202',
  [`${MLEnum.roqenet}-Rubble Zone B`]: '#FF9002',
  [`${MLEnum.roqenet}-Rubble Zone D`]: '#FF4F02',
  [`${MLEnum.roqenet}-Rubble Zone C`]: '#FF6602',
  // [`${MLEnum.roqenet}-Rubble Zone E`]: '#FF0000',
  [`${MLEnum.roqenet}-Intact`]: '#00ff00',
  [`${MLEnum.roqenet}-Mechanical Joint`]: '#ffff00',
  [`${MLEnum.roqenet}-Mechanical Rubble Zone`]: '#ffff00',
  [`${MLEnum.roqenet}-Joint`]: '#FF0000',
};

export const fcnetColors = {
  [`${MLEnum.fcnet}-Class 0`]: '#d0d0d0',
  [`${MLEnum.fcnet}-Class 1`]: '#17317a',
  [`${MLEnum.fcnet}-Class 2`]: '#4660a7',
  [`${MLEnum.fcnet}-Class 3`]: '#869bd3',
};

/**
 * An object mapping of various roqenet zones and fcnet classes to their description translation keys
 */
export const colorZoneDescriptions: Record<string, string> = {
  [`${MLEnum.roqenet}-Rubble Zone A`]: 'RUBBLE_ZONE_A',
  [`${MLEnum.roqenet}-Rubble Zone B`]: 'RUBBLE_ZONE_B',
  [`${MLEnum.roqenet}-Rubble Zone C`]: 'RUBBLE_ZONE_C',
  [`${MLEnum.roqenet}-Rubble Zone D`]: 'RUBBLE_ZONE_D',
  [`${MLEnum.roqenet}-Intact`]: 'INTACT',
  [`${MLEnum.roqenet}-Mechanical Joint`]: 'MECHANICAL_JOINT',
  [`${MLEnum.roqenet}-Mechanical Rubble Zone`]: 'MECHANICAL_RUBBLE_ZONE',
  [`${MLEnum.roqenet}-Joint`]: 'JOINT',
  [`${MLEnum.fcnet}-Class 0`]: 'CLASS0',
  [`${MLEnum.fcnet}-Class 1`]: 'CLASS1',
  [`${MLEnum.fcnet}-Class 2`]: 'CLASS2',
  [`${MLEnum.fcnet}-Class 3`]: 'CLASS3',
  'Empty Data': 'EMPTY_DATA',
};

export const IS_TEST_ENV: boolean =
  process.env.REACT_APP_ENVIRONMENT !== 'prod';

export const consultingEmail: string = 'consulting.support@alsglobal.com';

export const JOB_TYPES = {
  IMAGES_QA_QC: 'logs.find_inconsistencies',
  CLEAN_IMAGES: 'litholens.cleanimages',
  DESURVEY: 'litholens.desurvey',
  COLOR_EXTRACTION: 'litholens.run_color_extraction',
  COLOR_CLUSTERING: 'litholens.run_color_clustering',
  PERCENTAGE_REPORT: 'litholens.percentage_report',
  ROQENET: 'litholens.roqenet',
  RQD: 'litholens.run_roqenet_rqd',
  FRACTURE_FREQUENCY: 'litholens.run_roqenet_ff',
  VEINNET: 'litholens.veinnet',
  VEIN_FREQUENCY: 'litholens.generate_vein_frequency_report',
  TEXTURE_REPORT: 'litholens.texture_report',
  BOXYNET: 'litholens.run_boxynet',
  EXPORT_TEXTURE_REPORT: 'litholens.texture_report_export',
  FCNET: 'litholens.fcnet',
};

export const ESC_KEY = 'Escape';
export const UP_KEY = 'ArrowUp';
export const DOWN_KEY = 'ArrowDown';
export const LEFT_KEY = 'ArrowLeft';
export const RIGHT_KEY = 'ArrowRight';

export const TRANSPARENT_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export const GOLDSPOT: string = 'goldspot';

/**
 * Viewer.js config options (base)
 *
 * See documentation:
 * - [Viewer.js Site](https://fengyuanchen.github.io/viewerjs/)
 * - [Viewer.js Docs](https://github.com/fengyuanchen/viewerjs/tree/main?tab=readme-ov-file#viewerjs)
 * - [Example of customized toolbar](https://github.com/fengyuanchen/viewerjs/blob/main/docs/examples/custom-toolbar.html)
 */
export const VIEWER_OPTIONS: Viewer.Options = {
  backdrop: true,
  button: true,
  keyboard: false,
  loading: true,
  toolbar: {
    zoomIn: 1,
    zoomOut: 1,
    oneToOne: 1,
    reset: 1,
    prev: 1,
    next: 1,
    play: {
      show: 0,
    },
    rotateLeft: 0,
    rotateRight: 0,
    flipHorizontal: 0,
    flipVertical: 0,
  },
  transition: false,
};

export const BOX_NO_REGEX = /^BOX-\d{1,}(-\d{1,})?$/;
